import { useState } from "react";
import { useEffect } from "react";
import Modal from "../../components/modal/Modal";
import "./MeditationEndPopup.scss";


function MeditationEndPopup({durationSec, closeModal, finishMeditation}) {
    let [min, setMin] = useState("00");
    let [sec, setSec] = useState("00");

    useEffect(()=>{
        setMin(String(Math.floor(durationSec/60)).padStart(2, "0"));
        setSec(String(durationSec%60).padStart(2, "0"));
    }, [durationSec])

    return (
        <Modal closeModal={closeModal} displayType="center">
            <div class="meditation-end-popup">
                <div class="meditation-end-title-wrapper">
                    <div class="title">
                        Would you like to end your meditation?
                    </div>
                    <div class="subtitle">
                        {min}:{sec}
                    </div>
                </div>
                <div class="meditation-end-button-wrapper"> 
                    <button class="gray" onClick={()=>closeModal()}>No</button>
                    <button class="point" onClick={()=>finishMeditation()}>Yes</button>
                </div>
            </div>
        </Modal>
    )
}
export default MeditationEndPopup;