import Modal from "../modal/Modal";
import { ReactComponent as LogoIcon } from "../../assets/svg/logo_icon.svg";
import { ReactComponent as LogoText } from "../../assets/svg/logo_text.svg";
import { ReactComponent as Kakao } from "../../assets/svg/kakao.svg";
import { ReactComponent as Google } from "../../assets/svg/google.svg";
import { ReactComponent as Naver } from "../../assets/svg/naver.svg";
import colorfulObjects from "../../assets/images/colorful_objects.png";
import './index.scss';

function Login({ closeModal }) {
    const socialLoginInfoList = [
        // { id: 'kakao', name: '카카오톡으로', color: '#FFE812', textColor: 'black', icon: <Kakao/>, loginURL: `${process.env.REACT_APP_SERVER_HOST}/oauth2/authorization/kakao` },
        { id: 'google', name: 'google', color: '#FFFFFF', textColor: 'black', icon: <Google/>, loginURL: `${process.env.REACT_APP_SERVER_HOST}/oauth2/authorization/google` },
        // { id: 'naver', name: '네이버로', color: '#2DB400', textColor: 'white', icon: <Naver/>, loginURL: `${process.env.REACT_APP_SERVER_HOST}/oauth2/authorization/naver` },
    ]

    return (
        <Modal closeModal={closeModal} displayType="center">
            <div className="modal-wrapper login">
                <img src={colorfulObjects}/>
                <LogoIcon className="login-logo-icon"/>
                <LogoText className="login-logo-text"/>
                <span>
                    Sign up to enjoy a variety of meditation content!
                </span>
                {
                    socialLoginInfoList.map((social)=>(
                        <button 
                            className="login-button"
                            onClick={()=>{window.location.href = social.loginURL}}
                            style={{background: social.color, color: social.textColor}}
                        >
                            { social.icon }
                            Start with {social.name}
                        </button>
                    ))
                }
                <span
                    class="login-privacy-policy" 
                >   
                    By signing in, you agree to our&nbsp;
                    <a
                        href={process.env.REACT_APP_POLICY_URL + "terms"}
                        target="_blank"
                    >
                        Terms of Service
                    </a>
                    &nbsp;and&nbsp;
                    <a
                        href={process.env.REACT_APP_POLICY_URL + "privacy-policy"}
                        target="_blank"
                    >
                        Privacy Policy
                    </a>
                </span>

            </div>
        </Modal>
    )
}
export default Login;