function ShowStatus({ faceDetected, status }) {
    const FACE_NOT_DETECTED = 'No face detected. Please try again or refresh the page.';
    const MEDITATION_GUIDE = 'Close your eyes';
    const MEDITATION_START = 'Beginning meditation';
    const MEDITATION_ING = 'Meditation is in progress';
    const MEDITATION_END = 'Ending meditation';

    return (
        <div className="show-status">
            {
                faceDetected
                ?
                <>
                { status === 'NONE' && MEDITATION_GUIDE }
                { status === 'START' && MEDITATION_START }
                { status === 'ING' && MEDITATION_ING }
                { status === 'END' && MEDITATION_END }
                </>
                : FACE_NOT_DETECTED
            }
        </div>
    )
}
export default ShowStatus;