const musicInfoList = [
    {
        musicId: 6,
        musicName: "Clair De Lune",
        musicPath: "https://nutrition-helper-bucket.s3.ap-northeast-2.amazonaws.com/music/ES_Debussy_+Suite+Bergamasque%2C+CD+82_+III.+Clair+de+lune+-+Mira+Ma.mp3",
        musicImagePath: "https://nutrition-helper-bucket.s3.ap-northeast-2.amazonaws.com/image/0_0.jpeg",
        musicDuration: 327,
        composer: "Debussy"
    },
    {
        musicId: 7,
        musicName: "No.1",
        musicPath: "https://nutrition-helper-bucket.s3.ap-northeast-2.amazonaws.com/music/ES_Satie_+Gymnopedie+No.+1+-+Mira+Ma.mp3",
        musicImagePath: "https://nutrition-helper-bucket.s3.ap-northeast-2.amazonaws.com/image/0_1.jpeg",
        musicDuration: 209,
        composer: "Gymnopedie"
    }
]
export default musicInfoList;